import React from 'react';

import './CareersPage.scss';
import CareersPageBanner from './CareersPagePageBanner/CareersPageBanner';

const CareersPage = () => {
  const content = {
    VP_STATEMENT_SECTION_HEADING: '',
    VP_STATEMENT_SECTION_STATEMENT_CONTENT: [
      'The four pillars of Relationships, Insights, Sales, and Experience form the basis of our business. To better serve our developments, our partners, realtors, and purchasers while realizing the mission of providing meaningful real estate experiences, we firmly believe in directing the path of innovation via smart leadership. ',
      'Our conviction is that the desire to excel is what motivates our collaborative expertise with both clients and partners. Understanding customer interests and how those interests relate to the market we are now functioning in is at the core of much of what Urbana Bueno does. We have given it our utmost attention to comprehend the needs and desires of all stakeholders in order to effectively formulate solutions using actionable intelligence. Today, experience is more precious and useful.',
    ],
  };

  return (
    <>
      <CareersPageBanner />

      <section className="project-fact-description-section">
        <div className="container">
          <div className="contact-us">
            <div className="container">
              <hr />
              <div className="contact-us-content">
                <div className="contact-us-column">
                  <h2>Media</h2>
                  <p>
                    <a href="mailto:media@urbanabueno.com">media@urbanabueno.com</a>
                  </p>

                  <h2>New Business</h2>
                  <p>
                    <a href="mailto:business@urbanabueno.com">business@urbanabueno.com</a>
                  </p>
                </div>

                <div className="contact-us-column">
                  <h2>Careers</h2>
                  <p>
                    Urbana Bueno is always searching for enthusiastic people who would be a wonderful addition to our
                    team; people who are visionary, dedicated, and who have a passion for design, real estate, and
                    creating amazing communities.
                  </p>
                  <p>If you meet the requirements, kindly email your CV and cover letter to:</p>
                  <p>
                    <a href="mailto:careers@urbanabueno.com">careers@urbanabueno.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersPage;
