import { useEffect, useState } from 'react';
import AppConst from '../../../App.const';

import './CoreServicesTabs.scss';

import { Accordion, AccordionTab } from 'primereact/accordion';

const CoreServicesTabs = () => {
  const isDesktop = () => {
    return window.innerWidth > AppConst.MIN_DESKTOP_WIDTH;
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDesktop(isDesktop());
    });
  }, []);

  const [desktop, setDesktop] = useState(isDesktop());

  const panels = [
    {
      header: 'Acquisition',
      index: '1',
      panel: {
        render: <div>panel data 1</div>,
        caption: 'ACQUISITION',
        heading: '',
        description: [
          "Urbana Bueno boasts a highly accomplished team that has consistently demonstrated its expertise in acquiring and disposing of assets across diverse investment vehicles and markets. Our team's extensive experience and capabilities on a global scale have allowed us to navigate various market conditions and consistently generate attractive risk-adjusted returns across multiple market cycles.",
          "With a proven track record of success, Urbana Bueno's team has honed its skills in identifying lucrative investment opportunities and executing strategic asset acquisitions. We possess a deep understanding of market dynamics and trends, enabling us to make informed decisions and capitalize on emerging opportunities.",
          'Equally important to our success is our ground-up operating capabilities. We possess the hands-on expertise and industry insights necessary to oversee the development and management of real estate projects from inception to completion. Our holistic approach allows us to optimize project performance and maximize returns for our investors.',
          "At Urbana Bueno, we pride ourselves on being a trusted partner with an unwavering commitment to delivering exceptional results. Our team's broad experience, global reach, and demonstrated ability to generate attractive risk-adjusted returns across market cycles make us the preferred choice for investors seeking superior real estate opportunities.",
          'Partner with Urbana Bueno to benefit from our expertise, strategic vision, and operational prowess in unlocking the full potential of your real estate investments.',
        ],
        infoColumns: [
          {
            columnHeader: 'COMPETITIVE ANALYSIS',
            columnDescription:
              'Understanding your operating environment with confidence will benefit your development. We do a thorough analysis of outside data, including price, market trends, and absorptions. Our investigation ranges from market transaction values and macro and micro trends to a comprehensive competitive absorption report and demand research.',
          },
          {
            columnHeader: 'DESIGN OF PRODUCTS',
            columnDescription:
              'Aligning a number of factors is necessary to produce a product that will appeal to the market. After deciding on the best site, we launch a successful product design initiative by identifying the target market and using our market intelligence. This is the basis for how product types, unit mix planning, designing for utility, livability, overall aesthetic, and sales success will all be influenced.',
          },
          {
            columnHeader: 'COST ANALYSIS',
            columnDescription:
              "Comparables are used to inform a strategic price analysis, which also considers market projections and intricate proforma statements. Our purpose is to develop a multifaceted pricing strategy that will maximize our clients' goals, including revenue and absorptions, while providing exceptional value and outcomes. Any pricing difficulty will have a solution because to UB Advisory's analytical prowess.",
          },
        ],
        quote: '-',
        narrator: '-',
        narratorAdditional: 'PENNYFARTHING HOMES',
        bannerHeadingAdditional: <></>,
        bannerHeading: '',
        bannerDescription:
          "While Urbana Bueno Advisory educates and supports the development lifecycle, we also provide impartial market information and product design services to the development industry. We have the flexibility to offer this specialized information if you need it to analyze a particular market to help you decide whether to make an acquisition, or if you're looking for project design and unit mix planning.",
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION info@urbanabueno.com',
        button: '',
        link: '',
      },
    },
    {
      header: 'Development',
      index: '1',
      panel: {
        render: <div>panel data 1</div>,
        caption: 'DEVELOPMENT',
        heading: '',
        description: [
          'At Urbana Bueno, we bring together the collective wisdom of our local teams, decades of experience, and an unwavering commitment to long-term value creation. This unique combination allows us to craft sophisticated projects that are meticulously designed by world-class architects.',
          'Our projects have earned a well-deserved reputation for setting new standards of success in their respective markets and product types. We go beyond the pursuit of financial returns; we aim to enhance cities and pioneer sustainable practices. By integrating insights from our local teams, we ensure that our projects align with the unique characteristics and needs of each location.',
          'With decades of experience under our belt, we have honed our ability to identify opportunities and mitigate risks effectively. This depth of knowledge allows us to navigate complex real estate landscapes with confidence, making informed decisions that lead to long-term value creation.',
          'At Urbana Bueno, we believe that our projects should transcend mere profitability. We aspire to leave a positive and lasting impact on the cities in which we operate. Our commitment to sustainability drives us to pioneer new practices that prioritize environmental stewardship and social responsibility.',
          'By combining insights from our local teams, leveraging our extensive experience, and upholding our commitment to long-term value creation, we create projects that not only deliver exceptional financial returns but also elevate the quality of life in our cities. Join us on our journey as we redefine the boundaries of excellence and shape the future of sustainable real estate development.',
        ],
        infoColumns: [
          {
            columnHeader: 'COMPETITIVE ANALYSIS',
            columnDescription:
              'Understanding your operating environment with confidence will benefit your development. We do a thorough analysis of outside data, including price, market trends, and absorptions. Our investigation ranges from market transaction values and macro and micro trends to a comprehensive competitive absorption report and demand research.',
          },
          {
            columnHeader: 'DESIGN OF PRODUCTS',
            columnDescription:
              'Aligning a number of factors is necessary to produce a product that will appeal to the market. After deciding on the best site, we launch a successful product design initiative by identifying the target market and using our market intelligence. This is the basis for how product types, unit mix planning, designing for utility, livability, overall aesthetic, and sales success will all be influenced.',
          },
          {
            columnHeader: 'COST ANALYSIS',
            columnDescription:
              "Comparables are used to inform a strategic price analysis, which also considers market projections and intricate proforma statements. Our purpose is to develop a multifaceted pricing strategy that will maximize our clients' goals, including revenue and absorptions, while providing exceptional value and outcomes. Any pricing difficulty will have a solution because to UB Advisory's analytical prowess.",
          },
        ],
        quote: '-',
        narrator: '-',
        narratorAdditional: 'PENNYFARTHING HOMES',
        bannerHeadingAdditional: <></>,
        bannerHeading: '',
        bannerDescription:
          "While Urbana Bueno Advisory educates and supports the development lifecycle, we also provide impartial market information and product design services to the development industry. We have the flexibility to offer this specialized information if you need it to analyze a particular market to help you decide whether to make an acquisition, or if you're looking for project design and unit mix planning.",
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION info@urbanabueno.com',
        button: '',
        link: '',
      },
    },
    {
      header: 'Marketing',
      index: '2',
      panel: {
        render: <></>,
        caption: 'MARKETING',
        heading: '',
        description: [
          'Urbana Bueno stands out from the competition through our distinctive and precisely targeted approach to advertising. We understand the importance of reaching a highly specific audience, and we excel at capturing their attention. Our team takes the time to thoroughly understand the pulse of your target market, enabling us to develop comprehensive advertising plans that effectively reach qualified prospects.',
          'Our approach is data-driven and strategic. We leverage data insights to inform our media, brand, and advertising strategies, ensuring that every aspect of our campaigns is optimized for success. By combining creativity with a deep understanding of your target audience, we create compelling and impactful content that resonates with potential buyers or investors.',
          "What truly sets us apart is our dedicated team's ability to adapt and evolve throughout the duration of a project. We understand that the real estate market can be dynamic and subject to rapid changes. Our team is agile and responsive, allowing your organization to pivot and switch gears seamlessly. We monitor the performance of our advertising campaigns closely and make necessary adjustments to maximize results and ensure a strong return on investment (ROI).",
          'At Urbana Bueno, we recognize that advertising is not a one-size-fits-all approach. Each project has its unique characteristics, and we tailor our strategies accordingly. By combining precision targeting, data-backed creativity, and the ability to adapt swiftly, we position your organization for success in the competitive real estate landscape.',
          'Partner with Urbana Bueno and experience the power of our comprehensive, results-driven advertising strategies. Together, we will reach your desired audience, generate qualified leads, and achieve a strong ROI, all while staying ahead of market trends and opportunities.',
        ],
        infoColumns: [
          {
            columnHeader: 'STRATEGY & POSITIONING',
            columnDescription:
              'Through the development of a unified brand, we capture the spirit of a neighborhood and the soul of a project. Beyond excellent design and communication, we create a compelling narrative and toolkit for marketing to show a unified brand - a brand that helps our sales teams and gives homebuyers accessible information.',
          },
          {
            columnHeader: 'LAUNCH A PLAN',
            columnDescription:
              'We create an articulate and logical strategy to successfully launch your project into the market using the newly molded brand identity and positioning approach. Timing, target market, media buying tactics, and the marketing campaign as they relate to prospecting and launching the sales program are all outlined in a layered plan developed in partnership with UB Marketing and our sales teams.',
          },
          {
            columnHeader: 'MARKETING EXECUTION',
            columnDescription:
              "The amount of projects the Urbana Bueno team has brought to market as a whole has given rise to a thorough understanding of what is needed for effective marketing execution. Our internal marketing, communications, sales, and media buyer collaborate to create a strategy that will ensure that the brand narrative is flawlessly communicated to the market. Each campaign's infinite elements are successfully managed on schedule and within budget.",
          },
        ],
        quote:
          'Our communities receive tremendous value from MLA Canada’s collaborative approach, particularly around positioning, product design, and marketing and sales strategies. As our ambassador, the sales force is second-to-none, with extensive training and product knowledge unseen in the market.',
        narrator: 'Jason Turcotte',
        narratorAdditional: 'CRESSEY DEVELOPMENT GROUP',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Understanding Project Marketing',
        bannerDescription:
          'With a tailored project marketing strategy, your development will realize success. Our team can guide you through the project marketing process to help you comprehend the Branding and Positioning, Launch Strategy, and Marketing Execution phases, and how they will pertain to your individual project.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION PROJECTMARKETING@MLACANADA.COM',
        button: '',
        link: '',
      },
    },
    {
      header: 'Sales and Lease Up',
      index: '3',
      panel: {
        render: <></>,
        caption: 'SALES AND LEASE UP',
        heading: '',
        description: [
          'At Urbana Bueno, our sales methodology is a top-notch, multi-tiered approach that has been meticulously refined through comprehensive training and management programs. We pride ourselves on consistently surpassing expectations by delivering exceptional sales results.',
          "Our internal sales and management teams are an invaluable asset, offering strategic guidance on all aspects of the sales process. With years of experience and a deep understanding of our buyers' needs, our teams add significant value to every project. Their expertise and insights directly contribute to the success of the sell-out, ensuring optimal outcomes.",
          'What sets us apart from other companies in the market is our competitive advantage. We have built an extensive global database and a wide-reaching network of buyer access. This invaluable resource allows us to connect with qualified buyers from around the world, maximizing the exposure and reach of our projects.',
          'Furthermore, our regional brokerage firm, Urbana Bueno Realty, amplifies our capabilities. With a strong presence in the real estate market, our brokerage arm brings additional expertise, market insights, and a vast network of clients and industry connections. This integration provides us with a unique advantage, enabling us to leverage our comprehensive resources to drive sales success.',
          'By combining our refined sales methodology, experienced teams, global database, and regional brokerage firm, Urbana Bueno offers a winning formula for achieving outstanding sales results. Partner with us to leverage our competitive edge and unlock the full potential of your real estate projects.',
        ],
        infoColumns: [
          {
            columnHeader: 'SALES STRATEGY',
            columnDescription:
              'We leverage our connections and knowledge to create custom sales strategies for each client and market. We know precisely what to do when to maximize results since they are precise yet flexible. We create a structured, personalized plan that is in line with the brand identity, geography, and buyer demographics using data from Urbana Bueno Marketing and the findings of our in-house Application and Positioning session.',
          },
          {
            columnHeader: 'SALES EDUCATION',
            columnDescription:
              'Exclusive to Urbana Bueno, our well-tuned sales team assures our customers of commitment and integrity. Every salesperson participates in an extensive training program that is exclusive to Urbana Bueno over the course of several days and weeks. With ongoing education and empowering management, the depth of experience is increased so that our team can reliably identify sales prospects.',
          },
          {
            columnHeader: 'SALES MANAGEMENT',
            columnDescription:
              "In order to maximize outcomes, we carefully choose the best team for your particular project and maintain their concentration, vigor, and energy throughout the project's sales cycle. By offering in-depth expertise and careful professionalism, we help realtors and potential clients build partnerships. The professional management of inventory, strategy modifications, correspondence, and administration highlights answers and eliminates problems.",
          },
        ],
        quote:
          'We greatly enjoy our working relationship with MLA Canada. They are always prepared and proactive, bringing new ideas to the table and executing at a high level. Strategies are well-conceived and always with our interests and objectives in mind.',
        narrator: 'Chris Colbeck',
        narratorAdditional: 'TOWNLINE',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Introducing the MLA Sales Force',
        bannerDescription:
          "Our unique and advanced sales systems will drive your project towards the results you aim for. From Sales Strategy to Sales Management, our highly-trained team is here to inform the progression. We are happy to enlighten you with more details of each element of our Sales Force's process, and how it connects with the development lifecycle.",
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION SERVICES@MLACANADA.COM',
        button: '',
        link: '',
      },
    },
    {
      header: 'Construction',
      index: '4',
      panel: {
        render: <></>,
        caption: 'CONSTRUCTION',
        heading: '',
        description: [
          "At Urbana Bueno, our team of experienced professionals takes pride in analyzing and mastering every detail of our projects, leaving no room for error. We understand that cutting costs on materials or compromising on quality will only result in higher costs and issues down the road. That's why we are committed to delivering projects that meet the highest functional standards, from inception to completion.",
          'Our comprehensive expertise spans both ground-up and redevelopment construction. With a reputation for well-managed, high-quality services, we have successfully brought numerous projects from design to reality. We believe in a complete turnkey approach, which allows us to provide the most effective cost and time savings for our clients. By overseeing all aspects of the construction process, we ensure seamless coordination and streamlined execution, eliminating unnecessary delays and cost overruns.',
          'Efficiency is at the core of our operations. Our team is dedicated to performing all services with the utmost efficiency, optimizing resources, and maximizing productivity. We understand the importance of delivering projects on time and on budget, and we are committed to meeting these milestones.',
          'With Urbana Bueno, you can trust that your project will be handled with meticulous attention to detail and a focus on delivering the highest quality results. Our experienced professionals, comprehensive expertise, and turnkey approach ensure that every aspect of your project is managed effectively, from planning and design to construction and delivery.',
          'Partner with Urbana Bueno to experience the confidence and peace of mind that comes with our commitment to excellence, efficiency, and delivering projects that exceed expectations.',
        ],
        infoColumns: [
          {
            columnHeader: 'VALUE ENGINEERING',
            columnDescription:
              'Value engineering is an integral part of our pre-construction process and a key component of our success. Substantial value engineering efforts are made throughout the design process to provide the most efficient products, materials, and designs in to our projects. We identify areas where function can be improved, ongoing maintenance costs reduced or initial cost can be improved.',
          },
          {
            columnHeader: 'PRE-CONSTRUCTION',
            columnDescription:
              'We are at the table for a project from conception to completion. We are committed to the process from the initial stages, through major construction and until the last punch-list is done. Using a thorough pre-construction process that is essential for long-term success, we ensure a project is financially viable, that documents and specifications are complete, design is efficient, and schedules are attainable. From start to finish, we are working to provide outstanding outcomes.',
          },
          {
            columnHeader: 'CONSTRUCTION MANAGEMENT',
            columnDescription:
              'We build teams with experience that pay attention to the details. Our transparent approach and open communication has proven to be successful time and time again. We deploy best in class technology that allows our teams to track drawings, manage information, and keep all stake holders informed, streamlining our process and improving the client experience.  Starting with conceptual estimates and managing costs from schematic design through permit issuance, we bring projects to fruition and deliver the highest quality product the market can offer.',
          },
        ],
        quote:
          'Our projects have been successful because MLA Canada worked with us to create the approaches and execution that set us apart in our markets. I would recommend the company as a strategic partner without hesitation or reservation.',
        narrator: 'Anthony S. Lanni',
        narratorAdditional: 'BENTALL KENNEDY',
        bannerHeadingAdditional: <></>,
        bannerHeading: '',
        bannerDescription:
          'MLA Communications works in collaboration with teams throughout the development lifecycle, while also acting as an independent expert on public relations, social media, and event planning for the Development Industry. If your requirements are limited to assistance with a public relations or social media campaign for your company, rather than a project, we can provide you with that specific expertise.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION COMMUNICATIONS@MLACANADA.COM',
        button: '',
        link: '',
      },
    },

    {
      header: 'Completion',
      index: '5',
      panel: {
        render: <></>,
        caption: 'Completion',
        heading: '',
        description: [
          'At Urbana Bueno, we understand that a successful transaction goes beyond the completion of a deal. It is about creating a positive experience and fostering long-term brand equity by nurturing strong relationships between the developer and the buyer throughout the entire completion process.',
          'To enhance the transaction experience, our team utilizes innovative systems, including the UB app, for efficient contract management. By leveraging technology, we eliminate the need for paper-based processes, making the entire transaction more sustainable and convenient for all parties involved. This digital approach ensures accuracy, completeness, and proper execution of contracts and communications, even in an ever-changing marketplace.',
          'Our team is dedicated to staying on the right track, ensuring that every step of the transaction process is handled with precision and attention to detail. We understand the importance of accurate and timely communication, which is why we provide intensive and detailed reports to keep all stakeholders informed and aligned throughout the transaction journey.',
          'By prioritizing transparency, accuracy, and convenience, Urbana Bueno creates a seamless and efficient transaction experience for our clients. We believe that clear communication and streamlined processes contribute to the overall success of a transaction, leaving a lasting positive impression and strengthening the relationship between the developer and the buyer.',
          'Partner with Urbana Bueno to benefit from our commitment to excellence in transaction management. Together, we will navigate the complexities of the process, leverage innovative technology, and ensure a smooth and rewarding experience for all parties involved.',
        ],
        infoColumns: [
          {
            columnHeader: 'CONTRACT MANAGEMENT',
            columnDescription:
              'Our team uses innovative systems like UBApp for contract management, eliminating paper and making the process sustainable and convenient for all parties. Ensuring that contracts and communications are accurate, complete and properly executed is critical, especially in an ever-changing marketplace. Our team is always on the right track. Intensive and detailed reporting is also clear and timely.',
          },
          {
            columnHeader: 'CUSTOMER EXPERIENCE',
            columnDescription:
              "We recognize the value of homeowner feedback and encourage a two-way flow of information in every interaction. Every touchpoint is a positive experience, with timely and effective customer communication throughout the process. We are happy to provide language support if needed. Our approach is sensitive and considerate, treating the developer's brand with the utmost respect.",
          },
          {
            columnHeader: 'CLOSING SERVICES',
            columnDescription:
              'Successful Closings depends on professionalism and efficiency. Our sales representatives work together to streamline the process and provide a seamless experience for homeowners. Our documents are 100% carefully drafted, our due diligence is accurately executed, and our demeanor is always confident, optimistic and friendly.',
          },
        ],
        quote:
          'Our communities receive tremendous value from MLA Canada’s collaborative approach, particularly around positioning, product design, and marketing and sales strategies. As our ambassador, the sales force is second-to-none, with extensive training and product knowledge unseen in the market.',
        narrator: 'Jason Turcotte',
        narratorAdditional: 'CRESSEY DEVELOPMENT GROUP',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Experienced Lease-Up Services',
        bannerDescription:
          'Our approach to realizing a successful lease-up campaign is nothing short of exceptional. MLA Canada’s comprehensive experience and knowledge are employed to maximize revenue and stabilize tenancy. We encourage you to contact us with any lease-up inquiries – we would be happy to discuss our strategy and how it may benefit your unique project.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION LEASEUP@MLACANADA.COM',
        button: '',
        link: '',
      },
    },
  ];

  const HeaderTemplate = (options: any, index: string, header: string) => {
    return (
      <div className="csp-tabs-header-template" onClick={options.onClick}>
        <label className="header-index">{index}</label>
        <label className="header-label">{header}</label>
      </div>
    );
  };

  const PanelTemplate = (panel: any) => {
    const data = panel.data;
    return (
      <div className="csp-tab-panel">
        <h2 className="csp-tp-caption">{data.caption}</h2>
        <h3 className="csp-tp-heading">{data.heading}</h3>
        {data.description?.map((item: any) => {
          return <p className="">{item}</p>;
        })}

        <div className="csp-tp-columns">
          {data?.infoColumns?.map((info: any, index: number) => (
            <div className="csp-tp-column" key={index}>
              <h4 className="csp-tp-column-heading">{info.columnHeader}</h4>
              <h4 className="csp-tp-column-paragraph">{info.columnDescription}</h4>
            </div>
          ))}
        </div>

        {data?.data?.render}
      </div>
    );
  };

  const PanelTemplateMobile = (panel: any) => {
    const data = panel.data;
    return (
      <div className="csp-tab-panel" style={{ marginBottom: '40px' }}>
        <h2 className="csp-tp-caption" style={{ fontWeight: 'bold' }}>
          {data.caption}
        </h2>
        <h3 className="csp-tp-heading">{data.heading}</h3>
        {data.description?.map((item: any) => {
          return <p className="csp-tp-paragraph-">{item}</p>;
        })}

        <div className="csp-tp-columns-mobile">
          {data?.infoColumns?.map((info: any, index: number) => (
            <div className="csp-tp-column" key={index}>
              <h4 className="csp-tp-column-heading">{info.columnHeader}</h4>
              <p className="csp-tp-column-paragraph">{info.columnDescription}</p>
            </div>
          ))}
        </div>

        {data?.data?.render}
      </div>
    );
  };

  return (
    <section className="core-services-tabs">
      {desktop ? (
        <Accordion activeIndex={0}>
          {panels.map((data, index) => (
            <AccordionTab header={data?.header} key={index}>
              <PanelTemplate data={data?.panel} />
            </AccordionTab>
          ))}
        </Accordion>
      ) : (
        //   <TabView>
        //   {
        //     panels.map((data, index) => (
        //       <TabPanel headerTemplate={(options => HeaderTemplate(options, data?.index, data?.header))} key={index}>
        //         <PanelTemplate data={data?.panel}/>
        //       </TabPanel>
        //     ))
        //   }
        // </TabView>
        <div className="row">
          <div className="col-sm-12">
            {panels.map((data, index) => (
              <PanelTemplateMobile data={data?.panel} />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default CoreServicesTabs;
