import React from 'react';

import './ProjectFactHeroSection.scss';
import { Galleria } from 'primereact/galleria';
import AppConst from '../../../../App.const';

const ProjectFactHeroSection = (props: any) => {
  const galleriaBreakPoints = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  const itemTemplate = (item: any) => {
    // @ts-ignore
    return (
      <img
        src={item}
        style={{ width: '100%' }}
        alt="Image not found"
        className="pfv-galleria-img"
        onError={(e: any) => (e.target.src = AppConst.ERROR_IMAGE_SRC)}
      />
    );
  };

  const thumbnailTemplate = (item: any) => {
    return (
      <img
        src={item}
        alt="Image not found"
        onError={(e: any) => (e.target.src = AppConst.ERROR_IMAGE_SRC)}
        className="pfv-galleria-thumbnail"
      />
    );
  };

  return (
    <section className="project-fact-hero-section">
      <div className="container container-padding">
        <h4 className="pfv-city">{props.data.city}</h4>
        <h1 className="pfv-name">{props.data.name}</h1>
        <Galleria
          value={props.data.img_src}
          responsiveOptions={galleriaBreakPoints}
          numVisible={5}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
        />
      </div>
    </section>
  );
};

export default ProjectFactHeroSection;
