import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AppConst from '../App.const';
import Layout from './Layout';
import HomePage from './HomePage/HomePage';
import ProjectsPage from './ProjectsPage/ProjectsPage';
import NoPage from './NoPage/NoPage';
import CoreServicesPage from './CoreServicesPage/CoreServicesPage';
import ProjectFactViewPage from './ProjectsPage/ProjectFactViewPage/ProjectFactViewPage';
import VisionPage from './VisionPage/VisionPage';
import NewsFeedPage from './NewsFeedPage/NewsFeedPage';
import LeadershipPage from './LeadershipPage/LeadershipPage';
import ScrollToTop from '../scrollToTop';
import ContactusPage from './ContactusPage/ContactusPage';
import CareersPage from './CareersPage/CareersPage';
import { TermsOfServicePage } from './TermsOfServicePage';
import { PrivacyPolicyPage } from './PrivacyPolicyPage';

const PagesRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path={AppConst.PATH_HOME} element={<HomePage />} />
          <Route path={AppConst.PATH_PROJECTS}>
            <Route index element={<ProjectsPage />} />
            <Route path={AppConst.SUB_PATH_PROJECTS_PROJECT + '/:id'} element={<ProjectFactViewPage />} />
          </Route>
          <Route path={AppConst.PATH_CORE_SERVICES} element={<CoreServicesPage />} />
          <Route path={AppConst.PATH_DCH_MIAMI_VISION} element={<VisionPage />} />
          <Route path={AppConst.PATH_LEADER_SHIP} element={<LeadershipPage />} />
          <Route path={AppConst.PATH_NEWS_FEED} element={<NewsFeedPage />} />
          <Route path={AppConst.PATH_CONTACT} element={<ContactusPage />} />
          <Route path={AppConst.PATH_CAREERS} element={<CareersPage />} />
          <Route path={AppConst.PATH_TERM_SERVICE} element={<TermsOfServicePage />} />
          <Route path={AppConst.PATH_PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PagesRouter;
