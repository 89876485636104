import React from 'react';

import './ProjectFactDescriptionSection.scss';

const ProjectFactDescriptionSection = (props: any) => {
  const content = {
    PFV_LABEL_HOMES: 'HOMES',
    PFV_LABEL_BEDROOMS: 'BEDROOMS',
    PFV_LABEL_NUMBER_OF_HOMES: 'NUMBER OF HOMES',
    PFV_LABEL_FEATURE: 'FEATURE',
    PFV_LABEL_DEVELOPER: 'DEVELOPER',
    PFV_LABEL_STATUS: 'STATUS',
    PFV_LABEL_REGISTER: 'Register',
  };

  const getFontSize = () => {
    switch (props.data.headingSize) {
      case 'small':
        return '30px';
      default:
        return '4em';
    }
  };

  return (
    <section className="project-fact-description-section">
      <div className="container">
        <div className="pfv-description-grid">
          <div className="pfv-content">
            <h2 className="pfv-content-heading" style={{ fontSize: getFontSize() }}>
              {props.data.contentHeading}
            </h2>
            <p className="pfv-content-paragraph">{props.data.contentParagraph}</p>
          </div>
          <div className="pfv-features-col">
            <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_HOMES}</label>
              <br />
              <label className="pfv-feature-value">{props.data.features.homes}</label>
            </div>
            <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_BEDROOMS}</label>
              <br />
              <label className="pfv-feature-value">{props.data.features.bedrooms}</label>
            </div>
            <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_NUMBER_OF_HOMES}</label>
              <br />
              <label className="pfv-feature-value">{props.data.features.numberOfHomes}</label>
            </div>
            <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_DEVELOPER}</label>
              <br />
              <label className="pfv-feature-value">{props.data.features.developer}</label>
            </div>
          </div>
          <div className="pfv-features-col">
            <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_FEATURE}</label>
              <br />
              {props.data.features.other?.map((item: any) => {
                return <li className="pfv-feature-value-other">{item}</li>;
              })}
            </div>
            {/* <div className="pfv-feature">
              <label className="pfv-feature-label">{content.PFV_LABEL_STATUS}</label>
              <br />
              <label className="pfv-feature-value">{props.data.features.status}</label>
            </div>
            <div className="pfv-feature">
              <button className="pfv-register-button">{content.PFV_LABEL_REGISTER}</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="pfv-g-map-container">
        <iframe id="gmap_canvas" className="pfv-g-map-iframe" src={props.data.googleMapSrc} />
      </div>
    </section>
  );
};

export default ProjectFactDescriptionSection;
