import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectFactHeroSection from './ProjectFactHeroSection/ProjectFactHeroSection';
import ProjectFactDescriptionSection from './ProjectFactDescriptionSection/ProjectFactDescriptionSection';
import AppConst from '../../../App.const';
import AppStatics from '../../../App.statics';

const ProjectFactViewPage = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    city: '',
    name: '',
    img_src: [],
    contentHeading: '',
    contentParagraph: '',
    features: {
      homes: '',
      bedrooms: '',
      numberOfHomes: '',
      developer: '',
      status: '',
    },
  });

  useEffect(() => {
    fetch(`${AppConst.DATA_PATH_PROJECT_VIEW}/${id}.${AppStatics.getLanguage()}.json`)
      .then((r) => r.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  return (
    <>
      <ProjectFactHeroSection data={data} />
      <ProjectFactDescriptionSection data={data} />
    </>
  );
};

export default ProjectFactViewPage;
