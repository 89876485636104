import React from 'react';
import LifecycleCard from './LifecycleCard/LifecycleCard';

import './HomePageDevelopmentLifecycleSection.scss';

const HomePageDevelopmentLifecycleSection = () => {
  const content = {
    HP_DEV_LIFECYCLE_HEADING: 'Development Lifecycle',
    HP_LIFECYCLE_INTRO_HEADING: 'Successful Marketing. Improved Sales.',
    HP_LIFECYCLE_INTRO:
      'We manage every stage of the development lifecycle based on collaborations throughout the industry and decades of experience. In order to provide seamless integration of Core Services for the projects we support, we brainstorm laterally across specialist teams.',
    HP_LIFECYCLE_CARD_CONTENT: [
      {
        title: 'Acquisition',
        caption:
          'Urbana Bueno team has successfully acquired and disposed of assets across a wide range of investment vehicles and markets, demonstrating broad experience and capabilities globally, as well as a demonstrated track record of generating attractive risk-adjusted returns across market cycles. This expertise, coupled with ground-up operating capabilities, is the hallmark of Urbana Bueno.',
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684767755/DCH/1_vrvff8.png',
      },
      {
        title: 'Development',
        caption:
          'Combining insights from local teams, decades of experience and a commitment to long-term value creation, we craft sophisticated projects designed by world-class architects. Our projects have a reputation of setting standards for success in their respective markets and product types. Over and above financial returns, they improve cities and pioneer new sustainable practices. Combining insights from local teams, decades of experience and a commitment to long-term value creation.',
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684768051/DCH/2-modified_kl3qy2.png',
      },
      {
        title: 'Marketing',
        caption:
          'What sets Urbana Bueno apart is our unique, precisely targeted approach to advertising. We take the pulse of a highly specific audience and develop a comprehensive plan to reach qualified prospects. We craft and execute complex media, brand, and advertising strategies with data-backed creative. Our dedicated team adapts and evolves our products throughout the duration of a project, allowing your organization to pivot and switch gears at the drop of a dime, all while ensuring a strong ROI.',
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684768051/DCH/3-modified_a0f5hs.png',
      },
      {
        title: 'Sales & Lease-Up',
        caption:
          "Our top-notch, multi-tiered sales methodology has been refined through comprehensive training and management programs to produce sales that surpass expectations. Our internal sales and management teams add value by offering strategic guidance on all issues thanks to their years of experience, handling sales for close to 100 projects, and in-depth understanding of all our buyers' needs. This has a direct, positive impact on the success of the sell-out. We have a competitive advantage over other companies in the market because of our extensive global database and network of buyer access, as well as our regional brokerage firm (DCH REALTY).",
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684768051/DCH/4-modified_bxwomb.png',
      },
      {
        title: 'Construction',
        caption:
          'Our experienced professionals analyse and master every detail, leaving no room for error. We know that cutting costs for materials will only lead to higher costs down the road, and for that reason our projects are completed to the highest functional standard from beginning to completion. We provide comprehensive expertise in ground-up and redevelopment construction with a reputation for well-managed, high-quality services that successfully bring projects from design to reality. A complete turnkey approach provides Urbana Bueno with the most effective cost and time savings. We perform all services with the utmost efficiency and deliver projects on time and on budget.',
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684768051/DCH/5-modified_qfse7h.png',
      },
      {
        title: 'Completion',
        caption:
          'A successful transaction creates a good experience and long-term brand equity by strengthening the relationship between the developer and the buyer throughout the completion process. Our team uses innovative systems such as the UB app for contract management. This eliminates paper and makes the process sustainable and convenient for all involved. Especially in an ever-changing marketplace, it is very important to ensure that contracts and communications are accurate, complete and properly executed. Our team is always on the right track. Intensive and detailed reports are also clear and timely.',
        img: 'https://res.cloudinary.com/template-generator/image/upload/v1684768051/DCH/6-modified_afmbil.png',
      },
    ],
  };

  return (
    <section className="home-page-development-lifecycle-section">
      <div className="container">
        <h2 className="section-heading">{content.HP_DEV_LIFECYCLE_HEADING}</h2>
        <h3 className="intro-heading">{content.HP_LIFECYCLE_INTRO_HEADING}</h3>
        <p className="intro">{content.HP_LIFECYCLE_INTRO}</p>
        <div className="lifestyle-card-grid">
          {content.HP_LIFECYCLE_CARD_CONTENT.map((cardProps, i) => (
            <LifecycleCard key={i} {...cardProps} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomePageDevelopmentLifecycleSection;
