import React from 'react';

import './ContactusPage.scss';
import ContactusPageBanner from './VisionServicesPageBanner/CoreServicesPageBanner';

const ContactusPage = () => {
  const content = {
    VP_STATEMENT_SECTION_HEADING: '',
    VP_STATEMENT_SECTION_STATEMENT_CONTENT: [
      'The four pillars of Relationships, Insights, Sales, and Experience form the basis of our business. To better serve our developments, our partners, realtors, and purchasers while realizing the mission of providing meaningful real estate experiences, we firmly believe in directing the path of innovation via smart leadership. ',
      'Our conviction is that the desire to excel is what motivates our collaborative expertise with both clients and partners. Understanding customer interests and how those interests relate to the market we are now functioning in is at the core of much of what Urbana Bueno does. We have given it our utmost attention to comprehend the needs and desires of all stakeholders in order to effectively formulate solutions using actionable intelligence. Today, experience is more precious and useful.',
    ],
  };

  return (
    <>
      <ContactusPageBanner />

      <section className="project-fact-description-section">
        <div className="container">
          <div className="contact-us">
            <div className="container">
              <hr />
              <div className="contact-us-content">
                <div className="contact-us-column">
                  <h2>HEADQUARTER</h2>
                  <p>5050 Biscayne Blvd Suite 504</p>
                  <p>Miami Florida 3313</p>
                  <p>
                    <a href="tel:+17868822868">Tel +1(786) 882 2868</a>
                  </p>
                  <p>
                    <a href="mailto:info@urbanabueno.com">info@urbanabueno.com</a>
                  </p>
                </div>
                <div className="contact-us-column">
                  <h2>SOUTHEAST REGIONAL</h2>
                  <p>3350 Riverwood Parkway Suite 2140</p>
                  <p>Atlanta, Georgia 30339</p>
                  <p>
                    <a href="tel:+14042498310">Tel +1(404) 249 8310</a>
                  </p>
                </div>
                <div className="contact-us-column">
                  <h2>SOUTHWEST REGIONAL</h2>
                  <p>3600 Birch St Suite. 250</p>
                  <p>Newport Beach, CA 92660</p>
                  <p>
                    <a href="tel:+19496423529">Tel +1(949) 642 3529</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pfv-g-map-container">
          <iframe
            id="gmap_canvas"
            className="pfv-g-map-iframe"
            src={
              'https://www.google.com/maps/embed/v1/place?q=place_id:EjI1MDUwIEJpc2NheW5lIEJsdmQgc3VpdGUgNTA0LCBNaWFtaSwgRkwgMzMxMzcsIFVTQSIlGiMKFgoUChIJn19mWF-x2YgRC-GEeamYpBsSCXN1aXRlIDUwNA&key=AIzaSyAIacve-hWRVdkWMQCVeX9-vLVSy7lyKwU'
            }
          />
        </div>
      </section>
    </>
  );
};

export default ContactusPage;
