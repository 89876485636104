import React from 'react';

import './LifecycleCard.scss';
import { Button } from 'primereact/button';

const LifecycleCard = (props: any) => {
  const content = {
    BTN_LEARN_MORE: 'LEARN MORE',
  };

  return (
    <div className="hp-lifecycle-card">
      <div className="card-background">
        <img className="card-background-image" src={props.img} alt="image not loaded" />
        <div className="card-content">
          <h3>{props.title}</h3>
        </div>
        <div className="lcc-caption">
          <p>{props.caption}</p>
          {/* <Button label={content.BTN_LEARN_MORE} icon="pi pi-plus" iconPos="right" className="p-button-sm p-button-text"/> */}
        </div>
      </div>
    </div>
  );
};

export default LifecycleCard;
