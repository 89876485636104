import React from 'react';

import HomePageBannerSection from '../HomePage/HomePageBannerSection/HomePageBannerSection';

import './VisionPage.scss';
import VisionServicesPageBanner from './VisionServicesPageBanner/CoreServicesPageBanner';

const VisionPage = () => {
  const content = {
    VP_STATEMENT_SECTION_HEADING: 'Our Vision',
    VP_STATEMENT_SECTION_STATEMENT_CONTENT: [
      'At Urbana Bueno, we are passionate about what we do. We believe that real estate has the power to drive innovation, energize communities, and enhance lives. With vision and a commitment to excellence, we bring high-quality, sustainable real estate projects to life in various sectors, including office, retail, logistics, multifamily, hospitality, and mixed-use.',
      'Our approach to real estate development and management goes beyond industry norms. We strive to raise the standard for quality and sustainability, ensuring that our projects have a positive and lasting impact on the communities they serve. By prioritizing Relationships, Insights, Sales, and Experience as our guiding pillars, we create meaningful real estate experiences for our clients, partners, realtors, and purchasers.',
      'We believe that smart leadership and innovation are crucial to driving our industry forward. Through collaboration and expertise, we constantly push the boundaries of what is possible, always striving for excellence. Understanding the interests and needs of our customers and stakeholders is at the core of our approach. We invest considerable effort in comprehending market dynamics and translating that knowledge into actionable intelligence, allowing us to formulate effective solutions and deliver optimal outcomes.',
      "In today's world, experience is invaluable. We recognize the importance of providing exceptional experiences to our clients, partners, and all stakeholders involved in our projects. By focusing on delivering meaningful and valuable experiences, we aim to exceed expectations and build long-lasting relationships.",
      'Partner with Urbana Bueno to embark on a journey of innovation, quality, and sustainability. Together, we will create real estate experiences that leave a lasting positive impact on the communities we serve.',
    ],
  };

  return (
    <>
      <VisionServicesPageBanner />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="head-v-text">We love what we do</h2>
          </div>
          <div className="col-sm-12">
            <p className="">
              Real estate creates real innovation. Done right, and with vision, it has the power to energize
              communities. Transform industries. Enhance lives.
            </p>
            <p className="">
              From office to retail, logistics to multifamily, and hospitality to mixed-use, our businesses bring
              high-quality, sustainable real estate to life for everyone, everywhere.
            </p>
            <p className="">
              Our approach to developing and managing real estate raises the industry standard for quality and
              sustainability.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="head-v-text">{content.VP_STATEMENT_SECTION_HEADING}</h2>
          </div>
          <div className="col-sm-12">
            {content.VP_STATEMENT_SECTION_STATEMENT_CONTENT.map((data, index) => (
              <p className="" key={index}>
                {data}
              </p>
            ))}
          </div>
        </div>
      </div>

      <HomePageBannerSection />
    </>
  );
};

export default VisionPage;
