import React from 'react';

import ProjectCard from '../../../Components/ProjectCard/ProjectCard';

import './AcquisitionManagementSection.scss';

const AcquisitionManagementSection = (props: any) => {
  const content = {
    PP_FPS_HEADING: props.title,
    PP_FPS_CARDS_CONTENT: props.cardsData,
  };

  return (
    <section className="featured-projects-section">
      <div className="container">
        <h2 className="heading-three-sub">{content.PP_FPS_HEADING}</h2>
        <div className="fp-cards-grid">
          {content.PP_FPS_CARDS_CONTENT.map((data: any, index: number) => (
            <ProjectCard renderData={data} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AcquisitionManagementSection;
