import './CareersPageBanner.scss';

const CareersPageBanner = () => {
  const content = {
    HP_CSP_BANNER_HEADING: 'Careers',
    HP_CSP_BANNER_CAPTION: ' ',
  };

  return (
    <section className="core-services-page-banner">
      <div className="csp-banner">
        <div className="csp-banner-content">
          <h2 className="csp-banner-heading" style={{ background: '#0000006e' }}>
            {content.HP_CSP_BANNER_HEADING}
          </h2>
          <h6 className="csp-banner-caption" style={{ background: '#0000006e' }}>
            {content.HP_CSP_BANNER_CAPTION}
          </h6>
        </div>
        <img
          className="csp-banner-image"
          src={'https://res.cloudinary.com/template-generator/image/upload/v1685302858/DCH/our_vision_f2v6qu.webp'}
          alt="image not loaded"
        />
      </div>
    </section>
  );
};

export default CareersPageBanner;
