import React from 'react';
import { PanelMenu } from 'primereact/panelmenu';

import './HomePageSectionOne.scss';

const HomePageSectionOne = () => {
  const content = {
    SEC_1_H_RSI: 'Real Estate Intelligence propels profitability to new heights.',
    SEC_1_PARAGRAPH_1:
      'As pioneers in the realm of real estate investing, we are forging a path for a new era of Real Estate Development. Our approach is firmly rooted in collaboration and an unwavering focus on customer satisfaction. With a diverse team of experts spanning multiple disciplines, we are an innovative Development company dedicated to expanding the reach of projects and revolutionizing homeownership for individuals. We strongly believe that the future of real estate lies in human-centricity, where people are at the heart of every decision.',
    SEC_1_PARAGRAPH_2:
      'At the core of our operations lies a deep commitment to building meaningful relationships. Our team excels in creating influential sales platforms, tailored to cater to both boutique developments and expansive mixed-use masterplan communities. While we shape the physical landscapes of our cities, our passion and dedication extend beyond meeting expectations. We aspire to construct vibrant neighbourhoods’ that resonate with homebuyers on a profound level, elevating their sense of belonging and well-being.',
    SEC_1_PARAGRAPH_3:
      'Urbana Bueno embraces the challenges and opportunities presented by the ever-evolving real estate market with agility and foresight. Our commitment to excellence, integrity, and innovation drives us to deliver exceptional results for our clients and partners. Join us on this transformative journey, where we harness the power of Real Estate Intelligence to unlock the full potential of every project.',
    SEC_1_PARAGRAPH_4:
      'Discover a new standard of real estate development with Urbana Bueno. Together, we will accelerate profitability and create thriving communities that stand the test of time.',
    SEC_1_H_CS: 'Services',
  };

  const items = [
    {
      label: 'Acquisition',
      items: [{ label: '-' }],
    },
    {
      label: 'Development',
      items: [{ label: '-' }],
    },
    {
      label: 'Sales or Lease up',
      items: [{ label: '-' }],
    },
    {
      label: 'Construction',
      items: [{ label: '-' }],
    },
    {
      label: 'Completion',
      items: [{ label: '-' }],
    },
    {
      label: 'Customer Care',
      items: [{ label: 'CONTRACT MANAGEMENT' }, { label: 'CUSTOMER COMMUNICATION' }, { label: 'COMPLETION SERVICES' }],
    },
  ];
  return (
    <section className="home-page-section-one">
      <div className="container hp-grid">
        <div className="row">
          <div className="col-sm-12">
            <div className="hp-paragraph">
              <h2>{content.SEC_1_H_RSI}</h2>
              <p>{content.SEC_1_PARAGRAPH_1}</p>
              <p>{content.SEC_1_PARAGRAPH_2}</p>
              <p>{content.SEC_1_PARAGRAPH_3}</p>
              <p>{content.SEC_1_PARAGRAPH_4}</p>
            </div>
          </div>
        </div>

        {/* <div className="hp-services">
          <h2>{content.SEC_1_H_CS}</h2>
          <PanelMenu className="hp-services-panel-menu" model={items} style={{ width: '22rem' }} />
        </div> */}
      </div>
    </section>
  );
};

export default HomePageSectionOne;
