export const PrivacyPolicyPage = () => {
  return (
    <section className="vp-statement-section">
      <h1>PRIVACY STATEMENT</h1>
      <p>
        <b>ACKNOWLEDGEMENT AND ACCEPTANCE OF TERMS</b>
      </p>
      <p>
        Urbana Bueno LLC, Inc. is committed to protecting your privacy. This Privacy Statement sets forth our current
        privacy practices with regard to the information we collect when you or your computer interact with our website.
        By accessing www.UrbanaBueno.com, you acknowledge and fully understand Urbana Bueno Privacy Statement and freely
        consent to the information collection and use practices described in this Website Privacy Statement.
      </p>
      <p>
        <b>PARTICIPATING MERCHANT POLICIES</b>
      </p>
      <p>
        Related services and offerings with links from this website, including vendor sites, have their own privacy
        statements that can be viewed by clicking on the corresponding links within each respective website. Online
        merchants and others who participate in Urbana Bueno llc. services are encouraged to participate in industry
        privacy initiatives and to take a responsible attitude towards consumer privacy. However, since we do not have
        direct control over the policies or practices of participating merchants and other third parties, we are not
        responsible for the privacy practices or contents of those sites. We recommend and encourage that you always
        review the privacy policies of merchants and other third parties before you provide any personal information or
        complete any transaction with such parties.
      </p>
      <p>
        <b>INFORMATION WE COLLECT AND HOW WE USE IT</b>
      </p>
      <p>
        Urbana Bueno llc. collects certain information from and about its users three ways: directly from our Web Server
        logs, the user, and with Cookies.
      </p>
      <p>
        <b>Web Server Logs</b>
      </p>
      <p>
        When you visit our website, we may track information to administer the site and analyze its usage. Examples of
        information we may track include:
      </p>
      <ul>
        <li>Your Internet protocol address</li>
        <li>The kind of browser or computer you use</li>
        <li>Number of links you click within the site</li>
        <li>State or country from which you accessed the site</li>
        <li>Date and time of your visit</li>
        <li>Name of your Internet service provider</li>
        <li>Web page you linked to our site from</li>
        <li>Pages you viewed on the site</li>
      </ul>
      <p>
        <b>Use of Cookies</b>
      </p>
      <p>
        Urbana Bueno llc. may use cookies to personalize or enhance your user experience. A cookie is a small text file
        that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses
        to your computer. Cookies are uniquely assigned to you, and can only be read by a Web Server in the domain that
        issued the cookie to you.
      </p>
      <p>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. For example, if you
        personalize a web page, or navigate within a site, a cookie helps the site to recall your specific information
        on subsequent visits. Hence, this simplifies the process of delivering relevant content and eases site
        navigation by providing and saving your preferences and login information as well as providing personalized
        functionality.
      </p>
      <p>
        Urbana Bueno llc, Inc. reserves the right to share aggregated site statistics with partner companies but does
        not allow other companies to place cookies on our website unless there is a temporary, overriding customer value
        (such as merging into www.urbanabueno.com a site that relies on third-party cookies).
      </p>
      <p>
        You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify
        your browser setting to decline cookies. If you reject cookies by changing your browser settings, then be aware
        that this may disable some of the functionality on our website.
      </p>
      <p>
        You may manage how your browser handles cookies by adjusting its privacy and security settings. Browsers are
        different, so refer to instructions related to your browser to learn about cookie-related and other privacy and
        security settings that may be available.  You can opt-out of being targeted by certain Third-Party Ad-Servers,
        such as Google and other third parties that provide the technology to place ads on websites and track ad
        performance online using the Google Authenticated ads or choices to opt-out.
      </p>
      <p>
        <b>Personal Information Users</b>
      </p>
      <p>
        Visitors to our website can register to purchase services. When you register, we will request some personal
        information such as name, address, email, telephone number or facsimile number, account number and other
        relevant information. If you are purchasing a service, we will request financial information. Any financial
        information we collect is used only to bill you for the services you purchased. If you purchase by credit card,
        this information may be forwarded to your credit card provider. For other types of registrations, we will ask
        for the relevant information. You may also be asked to disclose personal information to us so that we can help
        with information to you. For example, such data may be warranted in order to provide online technical support
        and troubleshooting.
      </p>
      <p>
        We will not disclose personally identifiable information we collect from you to third parties without your
        permission except to the extent necessary including:
      </p>
      <ul>
        <li>To fulfill your service requests for services.</li>
        <li>To protect ourselves from liability,</li>
        <li>To respond to legal process or comply with law, or</li>
        <li>In connection with a merger, acquisition, or liquidation of the company.</li>
      </ul>
      <p>
        <b>USE OF WEB BEACONS OR GIF FILES</b>
      </p>
      <p>
        The www.urbanabueno.com web pages may contain electronic images known as web beacons – sometimes also called
        single-pixel gifs – that allow Urbana Bueno llc, Inc. to count users who have visited those pages and to deliver
        co-branded services Urbana Bueno llc, Inc. may include web beacons in promotional e-mail messages or newsletters
        in order to determine whether messages have been opened and acted upon. Some of these web beacons may be placed
        by third party service providers to help determine the effectiveness of our advertising campaigns or email
        communications. These web beacons may be used by these service providers to place a persistent cookie on your
        computer. This allows the service provider to recognize your computer each time you visit certain pages or
        emails and compile anonymous information in relation to those page views, which in turn enables us and our
        service providers to learn which advertisements and emails bring you to our website and how you use the site.
        Urbana Bueno llc. prohibits web beacons from being used to collect or access your personal information.
      </p>
      <p>
        <b>ACCESSING WEB ACCOUNT INFORMATION</b>
      </p>
      <p>
        We will provide you with the means to ensure that personally identifiable information in your web account file
        is correct and current. You may review this information, notify us of any changes in your personal information,
        or request that such information be removed from our records by e-mail info@urbanabueno.com.  Please be aware,
        however, that if you choose to have your personally identifiable information removed from our records, you will
        no longer be able to receive certain updated information from us, or participate in various functions of our
        website. We may disclose information about you to third parties if we have a good-faith belief that we are
        required to do so by law or legal process, to respond to claims, or to protect the rights, property or safety of
        Urbana Bueno llc.
      </p>
      <p>
        <b>CHANGES TO THIS STATEMENT</b>
      </p>
      <p>
        Urbana Bueno llc. has the discretion to occasionally update this privacy statement. When we do, we will also
        revise the “updated” date at the top of this Privacy page. We encourage you to periodically review this privacy
        statement to stay informed about how we are helping to protect the personal information we collect. Your
        continued use of the service constitutes your agreement to this privacy statement and any updates.
      </p>
      <p>
        <b>CONTACTING US</b>
      </p>
      <p>
        If you have questions regarding our Privacy Statement, its implementation, failure to adhere to this Privacy
        Statement and/or our general practices, please contact us:
      </p>
      <p style={{ margin: '0px' }}>Urbana Bueno llc.</p>
      <p style={{ margin: '0px' }}>3050 Biscayne Blvd suite 504</p>
      <p style={{ margin: '0px' }}>Miami Fl 33137</p>
      <p style={{ margin: '0px' }}>
        <a href="tel:+17868822868">Tel +1(786) 882 2868</a>
      </p>
      <p style={{ margin: '0px' }}>
        <a href="mailto:info@urbanabueno.com">info@urbanabueno.com</a>
      </p>
      <p>
        Urbana Bueno llc. will use commercially reasonable efforts to promptly respond and resolve any problem or
        question.
      </p>
    </section>
  );
};
