import React from 'react';

import './PersonCard.scss';

const PersonCard = (props: any) => {
  return (
    // <div className="dch-person-card">
    //   <img src={props.data?.picture} alt="image not loaded" className="pc-image" />
    //   <div className="pc-content">
    //     <h3 className="pc-heading">{props.data?.name}</h3>
    //     <div className="pc-divider" />
    //     <h4 className="pc-sub-heading">{props.data?.designation}</h4>
    //   </div>
    // </div>
    <>
      <div className="row" style={{ marginBottom: '20px' }}>
        <div className="col-sm-4">
          <img src={props.data?.picture} alt="image not loaded" className="auth-img" />
        </div>
        <div className="col-sm-8">
          <h4 style={{ textTransform: 'uppercase', marginBottom: 0, color: 'gray' }}>{props.data?.designation}</h4>
          <h3 className="pc-heading" style={{ marginTop: 0 }}>
            {props.data?.name}
          </h3>
          {props.data?.desc?.map((item: any) => {
            return <p className={item?.title ? `sub-upper-text` : ''}>{item?.text}</p>;
          })}
        </div>
      </div>
      <hr></hr>
    </>
  );
};

export default PersonCard;
