import React from 'react';
import HomePageHeroSection from './HomePageHeroSection/HomePageHeroSection';
import HomePageSectionOne from './HomePageSectionOne/HomePageSectionOne';
import HomePageBannerSection from './HomePageBannerSection/HomePageBannerSection';
import HomePageFeaturedDevelopmentsSection from './HomePageFeaturedDevelopmentsSection/HomePageFeaturedDevelopmentsSection';
import HomePageDevelopmentLifecycleSection from './HomePageDevelopmentLifecycleSection/HomePageDevelopmentLifecycleSection';
import HomePageFeaturedHeadlineSection from './HomePageFeaturedHeadlineSection/HomePageFeaturedHeadlineSection';
import HomePageBanner from './HomePageBanner/HomePageBanner';

function HomePage() {
  return (
    <>
      <HomePageBanner />
      {/* <HomePageHeroSection /> */}
      <HomePageSectionOne />
      <HomePageBannerSection />
      <HomePageFeaturedDevelopmentsSection />
      <HomePageDevelopmentLifecycleSection />
      <HomePageFeaturedHeadlineSection />
    </>
  );
}

export default HomePage;
