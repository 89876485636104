import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppConst from '../../App.const';
import AppStatics from '../../App.statics';

const NavigationTabs = ({ toggleOpenMenu }: any) => {
  // useEffect()

  const content = {
    HOME: 'Home',
    CORE_SERVICES: 'Core services',
    DCH_MIAMI_VISION: 'UB vision',
    PATH_LEADER_SHIP: 'Leadership',
    PROJECTS: 'Experience',
    CONTACT: 'Contact',
    CAREERS: 'Careers',
  };

  const [currentTab, setCurrentTab] = useState(AppStatics.currentTab);

  const onChangeTab = (tab: string) => {
    setCurrentTab(tab);
    AppStatics.currentTab = tab;
    toggleOpenMenu();
  };

  return (
    <React.Fragment>
      <Link
        className={currentTab == AppConst.PATH_HOME ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_HOME);
        }}
        to={AppConst.PATH_HOME}
      >
        {content.HOME}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_CORE_SERVICES ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_CORE_SERVICES);
        }}
        to={AppConst.PATH_CORE_SERVICES}
      >
        {content.CORE_SERVICES}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_DCH_MIAMI_VISION ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_DCH_MIAMI_VISION);
        }}
        to={AppConst.PATH_DCH_MIAMI_VISION}
      >
        {content.DCH_MIAMI_VISION}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_LEADER_SHIP ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_LEADER_SHIP);
        }}
        to={AppConst.PATH_LEADER_SHIP}
      >
        {content.PATH_LEADER_SHIP}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_PROJECTS ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_PROJECTS);
        }}
        to={AppConst.PATH_PROJECTS}
      >
        {content.PROJECTS}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_CONTACT ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_CONTACT);
        }}
        to={AppConst.PATH_CONTACT}
      >
        {content.CONTACT}
      </Link>
      <Link
        className={currentTab == AppConst.PATH_CAREERS ? 'selected' : ''}
        style={{ textDecoration: 'none' }}
        onClick={() => {
          onChangeTab(AppConst.PATH_CAREERS);
        }}
        to={AppConst.PATH_CAREERS}
      >
        {content.CAREERS}
      </Link>
    </React.Fragment>
  );
};

export default NavigationTabs;
