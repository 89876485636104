import PersonCard from '../../Components/PersonCard/PersonCard';
import HomePageBannerSection from '../HomePage/HomePageBannerSection/HomePageBannerSection';

import './LeadershipPage.scss';

const LeadershipPage = () => {
  const content = {
    VP_LEADERSHIP_TEAM_HEADING: 'leadership team',
    VP_LEADERSHIP_TEAM: [
      {
        name: 'Allan Gutierrez',
        designation: 'CEO Partner',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1684866733/DCH/allan_o6dpds.webp',
        desc: [
          {
            title: false,
            text: 'As Chief Executive Officer of the Urbana group of companies, Mr. Gutierrez is responsible for the strategic management of all of the group’s activities, with particular attention paid to the identification and evaluation of new investment opportunities, the management of the group’s existing investments, and the cultivation and maintenance of key capital and business relationships. Mr. Gutierrez also serves as CEO of Urbana Varro Opportunity Fund, I, II and II.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Gutierrez has over 30 years of commercial real estate experience. Prior to Urbana, Mr. Gutierrez was a Principal and the Chief Investment Officer of Songy Partners, the Founder and CEO of Alliance Partners, Inc., and a Vice President with Sonnenblick Goldman.',
          },
          {
            title: false,
            text: 'Mr. Gutierrez has also been engaged as an independent advisor on more than 100 occasions to deliver professional guidance on commercial real estate assets spanning 48 states. His areas of expertise include strategic management, assessment of investment feasibility sourcing investment capital, the acquisition and repositioning of underperforming real estate assets, debt restructuring, non-performing loan sales, and mergers and acquisitions of public and private real estate companies.',
          },
          {
            title: true,
            text: 'EDUCATION AND MEMBERSHIPS',
          },
          {
            title: false,
            text: 'A Detroit native, Mr. Gutierrez received his MBA from Michigan State University (Broad), his BS from Wayne State University, and pursued postgraduate real estate studies at the University of Houston. He is a member of the Atlanta Chapter of TIGER 21.',
          },
        ],
      },
      {
        name: 'Amaury Martinez',
        designation: 'Partner',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1684982698/DCH/amaury_la33ir.webp',
        desc: [
          {
            title: false,
            text: 'Mr. Martinez is responsible for and heavily involved in project plan development, construction operations, cost and schedule analysis, subcontractor negotiations, and understands how to implement plans effectively and take a project through completion.Mr. Martinez has collaborated with some of the top architects and designers in building high-end waterfront residences throughout South Florida.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Martinez has a long and successful track record both in Miami and Orlando. He has developed a variety of properties for residential, commercial, and mixed uses. In Orlando, his commercial developments include 1200 timeshare units. In addition to developing spec homes in Miami Mr. Martinez has been investing in commercial properties in and around Miami for over 25 years.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Miami Dade Community College',
          },
          {
            title: false,
            text: 'Associate Degree Business Administration',
          },
          {
            title: false,
            text: 'He is a member of (ARDA) American Resort Development Association ',
          },
        ],
      },
      {
        name: 'Ed Nolan',
        designation: 'Chief Financial Officer',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1684866733/DCH/ed_kjjixk.webp',
        desc: [
          {
            title: false,
            text: 'Mr. Nolan works together with Mr. Gutierrez as Chief Financial Officer of the Urbana Varro Companies.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Nolan has over 25 years of experience in the commercial and residential real estate industries. Prior to joining Urbana he was Chief Financial Officer of SunCal Companies, an Irvine, California, based developer of master planned communities. At SunCal, Mr. Nolan was directly responsible for the company’s accounting, tax and financial reporting, information systems, asset management and risk management functions. He was also involved in SunCal’s capital raising efforts, which included working with notable financial partners such as DE Shaw, Lehman Brothers Real Estate Partners, Lehman Brothers Holdings, Inc., Cerberus Real Estate, Bank Midwest, Credit Suisse, and California Bank & Trust. During his tenure the company’s asset base grew from approximately $250 million to over $4.1 billion.',
          },
          {
            title: false,
            text: 'Prior to joining SunCal, Mr. Nolan was President and Chief Financial Officer of Hudson Advisors in Singapore, where his responsibilities included oversight of the asset management, tax structuring, foreign currency management, and financial reporting for investments made in the Asia Pacific region by Lone Star Opportunity Funds III and IV. His primary focus was the development of platforms supporting non-performing loan acquisition, underwriting and management in Indonesia, Singapore and Taiwan, an effort employing more 225 individuals and managing in excess of 100,000 assets worth more than $2.1 billion. His past experience also includes serving as Vice-President for The Hampstead Group, LLC, a Dallas, Texas, based private equity firm, and as Vice-President of Finance, Treasurer and Director of Investor Relations for Bristol Hotels & Resorts.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Mr. Nolan is a Certified Public Accountant and holds a Bachelor of Science degree from Wayne State University in Detroit, Michigan.',
          },
        ],
      },
      {
        name: 'David Chavarria',
        designation: 'Sales Director',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1685430074/DCH/david_snszps_gzqy7z.png',
        desc: [
          {
            title: false,
            text: 'Mr. Chavarria oversees the Urbana Bueno sales Division , where he is responsible for designing the overall strategies, expanding the Urbana Bueno network; especially in the Americas, Europe, and Asia, and coordinating marketing strategies in a variety of overseas markets.Mr. Chavarria’s main purpose is to build effective and strong relationships with international brokers and Real Estate professionals and provide support to New Developments agents and teams in all their sales efforts worldwide.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Before joining Urbana Bueno he worked at Technion communications: too producer and project manager: managing over 1700 agents, with AT&T as main client, in south Florida, Colombia and Virgin Islands to successfully taken their sales from yielding 0.5 to 4.00%.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Computer science degree from Florida Atlantic university with an active real estate Brokers license since 2005 and mortgage loan originator in the states of Texas and Florida.',
          },
        ],
      },
      {
        name: 'Jorge Lamadrid',
        designation: 'Construction Manager',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1684982697/DCH/jorge_egawkj.webp',
        desc: [
          {
            title: false,
            text: 'Lamadrid is responsible for all phases of each development. Architectural and engineering designs are reviewed to assure that Urbana Bueno needs are met. Construction drawings are carefully coordinated to assure efficiency in construction. During construction, the construction is meticulously inspected by certified inspectors to assure strict compliance with all construction documents and plans specifications.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERINCE',
          },
          {
            title: false,
            text: 'Mr. Lamadrid has successfully planned and executed residential and commercial design and development services in the South Florida region for more than 35 years. Mr. Lamadrid  holds the highest category of general contractor’s license in the United States (Florida) for high rise and heavy construction, and consistently earns the praise and respect of its colleagues and clients in the industry.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Bachelor of Science degree from University of Pennsylvania ',
          },
          {
            title: false,
            text: 'Civil structure engineer and urban planning ',
          },
          {
            title: false,
            text: 'Master degree  Warton School of Business ',
          },
          {
            title: false,
            text: 'Licensed General Contractor State of Florida ',
          },
        ],
      },
      {
        name: 'Marshall S. Tullos',
        designation: 'VICE PRESIDENT / OPERATIONS',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685706905/DCH/Marshall_Tullos_for_social_Media-modified_vizgtu.jpg',
        desc: [
          {
            title: false,
            text: 'As Vice President of Operations, Mr. Tullos is responsible for the overall performance of Urbana’s owned/managed hotels and oversees the development and growth strategies for the organization.​',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Tullos has over 40 years of experience and is proud to be a fifth-generation hospitality industry professional.  Prior to joining Urbana, Mr. Tullos was the Regional Director of Operations for Peachtree Hotel Group for eight years, where he provided oversight and expertise managing a portfolio that included Hilton, Marriott, IHG and Choice branded hotels.  Responsible for a dozen full, select and limited-service hotels, he enjoyed working closely with General Managers to improve upon revenue maximization, service, quality and financial cost controls.  Mr. Tullos has served as Regional Director of Operations for Hospitality Management Corporation, a Dallas, Texas based hotel management company; as a Resort General Manager for American Liberty Hospitality at their state-of-the-art conference facility with championship golf course and spa in Northwest Louisiana; and, as a Regional Vice President of Operations for Texas based Highgate Hotels.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Mr. Tullos graduated from Cornell University with a Bachelor of Science degree in Hotel and Restaurant Management.  He also holds a Hotel Administrator certification from the Hotel and Lodging Association.',
          },
        ],
      },
      {
        name: 'Tom Fitzpatrick',
        designation: 'DEVELOPMENT & CONSTRUCTION',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1684866733/DCH/tom_clgh0x.webp',
        desc: [
          {
            title: false,
            text: 'Mr. Fitzpatrick is responsible for Urbana’s development, renovation and physical due diligence activities.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Fitzpatrick has over 26 years of real estate experience and has been personally responsible for executing a wide range of assignments associated with hospitality, retail, office, industrial, specialty, headquarter and land assets valued at more than $1.5B.',
          },
          {
            title: false,
            text: 'Mr. Fitzpatrick’s technical expertise encompasses due diligence, project management, development, construction budgeting, zoning, land use, entitlements, site acquisition and asset disposition. Projects Mr. Fitzpatrick worked on include: Pelican Grand Beach Resort (Ft. Lauderdale), Granada Suites Hotel (Atlanta), NSI Headquarters (Atlanta), Marriott Residence Inn (Atlanta), IBM Southeast Headquarters (Atlanta), Burger King World Headquarters (Miami), Thanksgiving Tower (Dallas), Grand Bay Plaza (Coconut Grove), Blue Lake Office Park (Boca Raton), Office Depot International Headquarters (Boca Raton), Golden Bear Plaza (North Palm Beach), FAU Research Park (Boca Raton), Lantern Square (Jacksonville), North Campus of Boca Raton Community Hospital (Boca Raton), Polaroid Headquarters (Cambridge), Welcome Inn America (Atlanta), First Union Plaza (Boca Raton), Days Inn I-4 (Orlando), American Bankers Headquarters (Miami), Sands Harbor Resort (Pompano Beach), Charter Schools (Boynton Beach), InPhyNet (Plantation), Forum III (West Palm Beach), Center Square Parking Garage (Jacksonville), Siemens Florida (Boca Raton), and the 1901 Building (Ft. Lauderdale).',
          },
          {
            title: false,
            text: 'Prior to forming Transaction Consultants in 1984, Mr. Fitzpatrick specialized in retail and land brokerage working as a founding member of Coldwell Banker Commercial Real Estate Services’ Tampa, Florida team. He also served as Vice President for a private investment / development firm at which he focused on identifying acquisition targets, performing due diligence and market analyses, assessing development feasibility, and handling the marketing and sale of commercial real estate assets.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Mr. Fitzpatrick holds a Bachelor of Science from Stetson University and is a licensed Florida Real Estate Broker.',
          },
        ],
      },
      {
        name: 'Maximillian Gutierrez',
        designation: 'DEVELOPMENT & ACQUISITIONS',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685706755/DCH/Gutierrez_Max-modified_utrogm.jpg',
        desc: [
          {
            title: false,
            text: 'Mr. Maximillian Gutierrez, our Development and Acquisitions Associate, is responsible for networking and investor relations for defined market segments He provides financial analysis, research, underwriting, and due diligence support to our senior management team.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Gutierrez has over 12 years of commercial real estate experience. Before taking on the Development and Acquisitions role, Maximillian worked as an Urbana leasing agent and property manager. He has several years working in in acquisitions, leasing, asset and construction management for various office, retail and hotel investments. Prior to Urbana, his experience with other companies includes underwriting for both an international private equity real estate firm and a national loan servicer.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'An Atlanta native, Mr. Gutierrez received his MBA from Emory University (Goizueta), and dual BBAs in Real Estate and Finance from the University of Georgia. He is also a licensed Real Estate Broker in Georgia and Florida. His professional memberships include both the Urban Land Institute (ULI) and the National Association of Office Parks (NAIOP).',
          },
        ],
      },

      {
        name: 'Steve Thurston',
        designation: 'CONTROLLER / CFO',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685707555/DCH/Thurston-modified_od4szc.jpg',
        desc: [
          {
            title: false,
            text: 'As Controller / CFO of Urbana Holdings, Mr. Thurston is responsible for all accounting and finance activities.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Thurston is a Certified Public Accountant with over 20 years of experience in the accounting and real estate industries. His areas of accounting specialization include real estate investment and development, estate tax planning, income tax planning, financial planning, tax audit representation and tax compliance. Mr. Thurston has extensive experience advising closely held businesses, their owners, and high-net-worth individuals by offering critical tax planning and consulting services.',
          },
          {
            title: false,
            text: 'Mr. Thurston has been controller for Urbana and its affiliated companies for over ten years. Prior to joining the Urbana organization was a member of Pannell Kerr Forster Worldwide.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Mr. Thurston graduated from Colorado State University with a BS in Business Administration (with concentrations in Accounting and Computer Information Systems). He is a member of the American Institute of Certified Public Accountants and is a Certified Public Accountant in the State of Colorado.',
          },
        ],
      },
      {
        name: 'Marcos Vidal',
        designation: 'CORPORATE DIRECTOR / REVENUE MANAGEMENT',
        picture: 'https://res.cloudinary.com/template-generator/image/upload/v1685707443/DCH/VIDAL_copy_nakyku.webp',
        desc: [
          {
            title: false,
            text: 'Originally from Arlington, TX, Marcos Vidal is the Corporate Director of Revenue Management for Urbana Holdings. He is responsible for revenue forecasting, profit opportunity analysis, and business evaluation for all market segments. He is committed to enhancing the long-term value of the investment entrusted to him by Urbana. While offering quality products and services at a competitive price, he believes in the value of providing excellent customer service, too. Marcos is committed to maintaining a safe work environment enriched by diversity and characterized by open communication, trust, and fair treatment. He maintains high ethical standards, follows all applicable laws, rules, and regulations, and respects local and national cultures.',
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Mr. Vidal has 25 years of revenue management experience and has been personally responsible for executing a wide range of assignments associated with hotel revenue management.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Business Administration AAS – Dallas CCCD',
          },
        ],
      },
      {
        name: 'Emmy Schmid',
        designation: 'CORPORATE DIRECTOR / HUMAN RESOURCES',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685707395/DCH/Schmid1600_8x10-modified_nshfgq.jpg',
        desc: [
          {
            title: false,
            text: "Emmy Schmid is Urbana's Corporate Director of Human Resources providing human resources support and solutions to owners and operators of nationally recognized hotel franchises and independent properties throughout the United States. Schmid has more than 15 years of experience as a leader in overseeing company culture, employee relations, training, workers compensation, payroll/compensation, safety, legal compliance and health/welfare benefits.",
          },
          {
            title: true,
            text: 'PROFESSIONAL EXPERIENCE',
          },
          {
            title: false,
            text: 'Prior to joining Urbana, Schmid worked as Corporate Human Resources Director for companies such as Hotel Equities in Atlanta, Miami-based Tecton/Desires Hospitality and Doramar Hotels, owner/operators of hotels in metro-Denver and Vail valley. She also enjoyed working for several years as a property Human Resources Director at the Bahia Mar Resort and Yachting Center, part of the Boca Resorts collection. She started her hospitality human resources career with Quorum Hotels at the Brown Palace Hotel, a luxury property in downtown Denver.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Ms. Schmid holds a bachelor’s degree in English from Carroll College.',
          },
        ],
      },
      {
        name: 'Linda Westmoreland',
        designation: 'CORPORATE CONTROLLER',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685707345/DCH/Westmoreland_8884_socialmedia-modified_khsmjq.jpg',
        desc: [
          {
            title: false,
            text: 'Ms. Westmoreland began her 40 year accounting career as a Budget Analyst for Georgia Institute of Technology. She possesses over 30 years of experience in financial statement preparation as a Controller for various Atlanta-based corporations. She began her hospitality industry experience as Controller for 20First Hospitality, working seven years at the famous Villa Christina and Retreat-Perimeter Center then later transitioning as staff accountant for both the Hyatt Regencyand Villa Christina entites. Ms. Westmoreland is pround of her time with 20First wherein she consolidated food & beverage financials with hotel financials through successful oversight of varying PMS, F&B and accounting software programs.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Ms. Westmoreland has a Bachelor of Science in Business Administration from Georgia Southwestern College and studied Managerial Accounting and Organizational Behavior and Administration at Old Dominion University, Norfolk, Va.',
          },
        ],
      },
      {
        name: 'Tammy Gillespie',
        designation: 'REGIONAL DIRECTOR OF SALES',
        picture:
          'https://res.cloudinary.com/template-generator/image/upload/v1685707295/DCH/Tammy_Gillespie_-_Picture_edited-modified_al623o.webp',
        desc: [
          {
            title: false,
            text: 'Originally from Dallas, TX, Tammy Gillespie is the Regional Director of Sales for Urbana Holdings. She is responsible for revenue generation and ensuring the long-term growth in all Urbana properties and segments. Tammy believes in a customer-focused approach to ensuring that each client gets what they want and need when they select Urbana properties for their destination or event. Tammy believes that teamwork is the key to success. She enables everyone around her, empowering them do their best and be successful. She enjoys making everyone smile, both associates and clients, by being open, trustworthy, and friendly. She ensures every program is the best it can be for the clients and the associates.',
          },
          {
            title: true,
            text: 'EDUCATION',
          },
          {
            title: false,
            text: 'Education Marketing AAS – Tarrant CCD',
          },
        ],
      },
    ],
  };

  return (
    <>
      <section className="vp-leadership-section">
        <h2 className="section-heading">{content.VP_LEADERSHIP_TEAM_HEADING}</h2>
        <div className="container">
          {content.VP_LEADERSHIP_TEAM.map((data, index) => (
            <PersonCard data={data} key={index} />
          ))}
        </div>
      </section>
      {/* <section className="vp-leadership-section">
        <h2 className="section-heading">{content.VP_LEADERSHIP_TEAM_HEADING}</h2>
        <div className="vp-leadership-team-grid">
          {content.VP_LEADERSHIP_TEAM.map((data, index) => (
            <PersonCard data={data} key={index} />
          ))}
        </div>
      </section> */}
      <HomePageBannerSection />
    </>
  );
};

export default LeadershipPage;
