import './ContactusPageBanner.scss';

const ContactusPageBanner = () => {
  const content = {
    HP_CSP_BANNER_HEADING: 'Contact Us',
    HP_CSP_BANNER_CAPTION:
      '“Time is more valuable than money. You can get more money, but you cannot get more time.” - John Rohn',
  };

  return (
    <section className="core-services-page-banner">
      <div className="csp-banner">
        <div className="csp-banner-content">
          <h2 className="csp-banner-heading" style={{ background: '#0000006e' }}>
            {content.HP_CSP_BANNER_HEADING}
          </h2>
          <h6
            className="csp-banner-caption"
            style={{ background: '#0000006e', fontSize: '17px', padding: '15px', textAlign: 'center' }}
          >
            {content.HP_CSP_BANNER_CAPTION}
          </h6>
        </div>
        <img
          className="csp-banner-image"
          src={'https://res.cloudinary.com/template-generator/image/upload/v1685302818/DCH/contact_page_gotaqn.webp'}
          alt="image not loaded"
        />
      </div>
    </section>
  );
};

export default ContactusPageBanner;
