import React, { useEffect, useState } from 'react';
import FeaturedProjectsSection from './FeaturedProjectsSection/FeaturedProjectsSection';
import CurrentProjectsSection from './CurrentProjectsSection/CurrentProjectsSection';
import PreviousProjectsSection from './PreviousProjectsSection/PreviousProjectsSection';
import AppConst from '../../App.const';
import AppStatics from '../../App.statics';
import AcquisitionManagementSection from './AcquisitionManagementSection/AcquisitionManagementSection';

const ProjectsPage = () => {
  const [projectsData, setProjectsData] = useState({
    acquisitionManagementProjects: [],
    acquisitionRenovationProjects: [],
    condoProjects: [],
    mixedUseProjects: [],
    managementProjects: [],
  });

  useEffect(() => {
    fetch(`${AppConst.DATA_PATH_PROJECTS}/${AppConst.FILE_NAME_PROJECTS_DATA_FILE}.${AppStatics.getLanguage()}.json`)
      .then((r) => r.json())
      .then((data) => {
        setProjectsData(data);
      });
  }, []);

  return (
    <>
      <AcquisitionManagementSection
        title="ACQUISITION & MANAGEMENT"
        cardsData={projectsData?.acquisitionManagementProjects}
      />
      <AcquisitionManagementSection
        title="ACQUISITION & RENOVATION"
        cardsData={projectsData?.acquisitionRenovationProjects}
      />
      <AcquisitionManagementSection
        title="CONDO DEVELOPMENT CONSTRUCTION MANAGEMENT"
        cardsData={projectsData?.condoProjects}
      />
      <AcquisitionManagementSection title="MIXED USED DEVELOPMENT" cardsData={projectsData?.mixedUseProjects} />
      <AcquisitionManagementSection title="MANAGEMENT" cardsData={projectsData?.managementProjects} />
      {/* <FeaturedProjectsSection cardsData={projectsData?.featuredProjects} />
      <CurrentProjectsSection />
      <PreviousProjectsSection /> */}
    </>
  );
};

export default ProjectsPage;
