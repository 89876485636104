import React from 'react';
import PropTypes from 'prop-types';

import './Footer.scss';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import NavigationTabs from '../Navbar/NavigationTabs';
import { Button } from 'primereact/button';
import AppConst from '../../App.const';
import { Link } from 'react-router-dom';

Footer.propTypes = {};

function Footer(props: any) {
  return (
    <footer className="footer">
      <div className="container">
        <hr />
        <div className="footer-content">
          <div className="footer-column">
            <NavigationTabs />
          </div>
          <div className="footer-column">
            <label>MAIN OFFICE</label>
            <label>3050 Biscayne Blvd suite 504</label>
            <label>Miami Fl 33137</label>
            <label>USA</label>
            <br />
            <label>-</label>
            <label></label>
            <label></label>
            <label></label>
          </div>
          <div className="footer-column">
            <Link style={{ textDecoration: 'none' }} to={AppConst.PATH_TERM_SERVICE}>
              Terms of Service
            </Link>
            <Link style={{ textDecoration: 'none' }} to={AppConst.PATH_PRIVACY_POLICY}>
              Privacy Policy
            </Link>
          </div>
          <div className="footer-column">
            <div className="flex social-media">
              <a href="https://www.linkedin.com/company/urbana-bueno" target="_blank" rel="noreferrer">
                <i className="pi pi-linkedin" />
              </a>
              <a href="https://www.facebook.com/urbanabuenosite/" target="_blank" rel="noreferrer">
                <i className="pi pi-facebook" />
              </a>
              <a href="https://www.instagram.com/buenourbana/" target="_blank" rel="noreferrer">
                <i className="pi pi-instagram" />
              </a>
              {/* <i className="pi pi-instagram" />
              <i className="pi pi-twitter" />
              <i className="pi pi-facebook" />
              <i className="pi pi-youtube" />
              <i className="pi pi-linkedin" /> */}
            </div>
            <label>URBANA BUENO NEWSWIRE</label>
            <p className="footer-description">
              Subscribe to the URBANA BUENO Newswire and become real estate intelligent with the latest market news,
              development and design updates, and presale information delivered right to your inbox 5 days a week.
            </p>

            <a className="subscribe">subscribe now</a>
            <img src={AppConst.BLACK_LOGO_IMG_SRC} alt="logo" className="footer-logo" />
            <label className="small-text">© {new Date().getFullYear()} URBANA BUENO | Privacy Policy</label>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
