import './VisionServicesPageBanner.scss';

const VisionServicesPageBanner = () => {
  const content = {
    HP_CSP_BANNER_HEADING: 'Teamwork',
    HP_CSP_BANNER_CAPTION: "Success is best when it's shared. - Howard Schultz",
  };

  return (
    <section className="core-services-page-banner">
      <div className="csp-banner">
        <div className="csp-banner-content">
          <h2 className="csp-banner-heading" style={{ background: '#0000006e' }}>
            {content.HP_CSP_BANNER_HEADING}
          </h2>
          <h6
            className="csp-banner-caption"
            style={{ background: '#0000006e', fontSize: '17px', padding: '15px', textAlign: 'center' }}
          >
            {content.HP_CSP_BANNER_CAPTION}
          </h6>
        </div>
        <img
          className="csp-banner-image"
          src={'https://res.cloudinary.com/template-generator/image/upload/v1685302711/DCH/ourvision_kdgkrq.jpg'}
          alt="image not loaded"
        />
      </div>
    </section>
  );
};

export default VisionServicesPageBanner;
